<template>
  <div class="clientes">
    <h1 class="titulo-card fs-4">Servidores</h1>
    <div class="form-group card-principal">
      <nav>
        <div class="nav nav-tabs active" id="nav-tab" role="tablist">
          <a class="nav-link" :class="{'active': activeTab === '#nav-olt'}" @click="setTab('#nav-olt')" id="nav-olt-tab" href="#nav-olt" role="tab" aria-controls="nav-olt" aria-selected="true">
            <font-awesome-icon :icon="['fas', 'server']" fixed-width class="me-1"/>
            OLT's
          </a>
          <a class="nav-link" :class="{'active': activeTab === '#nav-bng'}" @click="setTab('#nav-bng')" id="nav-bng-tab" href="#nav-bng" role="tab" aria-controls="nav-bng" aria-selected="true">
            <font-awesome-icon :icon="['fas', 'wifi']" fixed-width class="me-1"/>
            BNG's
          </a>
        </div>
      </nav>
      <div class="tab-content" id="nav-tabContent">
        <!-- olt's -->
        <div class="tab-pane fade show" :class="{'active': activeTab === '#nav-olt'}" id="nav-olt" role="tabpanel" aria-labelledby="nav-olt-tab">
          <ServidoresOLT/>
        </div>
        <!-- olt's -->

        <!-- bng's -->
        <div class="tab-pane fade show" :class="{'active': activeTab === '#nav-bng'}" id="nav-bng" role="tabpanel" aria-labelledby="nav-bng-tab">
          <ServidoresBNG/>
        </div>
        <!-- bng's -->
      </div>
    </div>
  </div>
</template>

<script>
import ServidoresBNG from '@/views/ServidoresBNG.vue'
import ServidoresOLT from '@/views/ServidoresOLT.vue'

export default {
  name: 'Servidores',
  components: {
    ServidoresBNG,
    ServidoresOLT,
  },
  data() {
    return {
      activeTab: '#nav-olt',
    }
  },
  methods:{
    setTab(name) {
      this.activeTab = name;
    },
  },
  created() {
    if (this.$route.hash) {
      this.activeTab = this.$route.hash;
    }
  },
}
</script>
