<template>
  <div class="clientes">
    <div class="row justify-content-between">
      <div class="col-12 input-group mb-3">
        <a href="/configuracoes/servidores/bngs/novo" class="btn btn-success">
          <font-awesome-icon :icon="['fas', 'plus']" fixed-width class="me-1"/>Adicionar
        </a>
        <button v-on:click="handleSeeRadiusIPs()" class="btn btn-outline-primary">
          <font-awesome-icon :icon="['fas', 'network-wired']" fixed-width class="me-1"/>IP's de origem
        </button>
      </div>

      <div class="col-12 input-group mb-3">
        <input
            v-on:keyup.enter="handlePesquisa()"
            type="text"
            class="form-control"
            placeholder="Pesquisar"
            aria-label="Pesquisar"
            aria-describedby="button-addon2">
        <button v-on:click="handlePesquisa()" class="btn btn-outline-secondary" type="button" id="button-addon2">
          <font-awesome-icon :icon="['fas', 'search']" fixed-width class="me-1"/>
        </button>
      </div>
    </div>
    <div class="d-flex">
      <table class="table table-sm-pc table-striped table-hover">
        <thead>
          <tr>
            <th>Nome</th>
            <th>Identificação</th>
            <th width="150">IP</th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="bng in listaBngs" :key="bng.id" @click="$router.push(`/configuracoes/servidores/bngs/${bng.id}`)" :class="cssBng(bng)" class="cursor-pointer">
              <td scope="row">{{bng.description}}</td>
              <td>{{bng.identifier}}</td>
              <td>{{bng.ip}}</td>
            </tr>
          </tbody>
      </table>
    </div>
    <Paginador v-model="paginador" @change-paginacao="onChangePaginacao"/>
  </div>
</template>

<script>
import Paginador from '@/components/Paginador.vue'
import api from '@/modules/api'
import dialogo from '@/modules/dialogo'
import loading from '@/modules/loading'
import clone from '@/modules/clone'
import model from '@/model'

export default {
  name: 'ServidoresBNG',
  components: {
    Paginador,
  },
  data() {
    return {
      /** @type {model.paginador} */
      paginador: clone(model.paginador),
      radiusIps: [],
      listaBngs: [],
      listaIPPools: [],
    }
  },
  methods: {
    carregaIPPools() {
      api.get(`/isp/pools?paginate.cancel`).then(res => {
        this.listaIPPools = res.data.items;
      }).catch(() => {
        setTimeout(() => {
          this.carregaIPPools();
        }, 1000);
      });
    },
    handlePesquisa() {
      loading(true);

      api.get(`/isp/bngs?${this.paginador.requestParams}&orderBy[description]=asc`).then(res => {
        loading(false);
        this.listaBngs = res.data.items;
        this.paginador.pagina = res.data.page;
        this.paginador.totalItens = res.data.totalItems;
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    loadRadiusIps() {
      loading(true);

      api.get(`/isp/radius-ips?paginate.cancel&orderBy[ip]`).then(res => {
        loading(false);
        this.radiusIps = res.data.items;
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    onChangePaginacao() {
      this.handlePesquisa();
    },

    handleSeeRadiusIPs() {
      this.$router.push('/configuracoes/servidores/radius-ips');
    },

    cssBng(bng) {
      if (!bng.active) {
        return 'text-muted';
      }

      return 'text-info';
    },
  },

  created() {
    this.handlePesquisa();
    this.carregaIPPools();
    this.loadRadiusIps();
  }
}
</script>
