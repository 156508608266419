<template>
  <div class="clientes">
    <div class="d-flex justify-content-between">
      <div class="input-group mb-3">
        <button v-on:click="handleAdicionar()" class="btn btn-success">
          <font-awesome-icon :icon="['fas', 'plus']" fixed-width class="me-1"/>Adicionar
        </button>
      </div>

      <div class="input-group mb-3">
        <input
            v-on:keyup.enter="handlePesquisa()"
            type="text"
            class="form-control"
            placeholder="Pesquisar"
            aria-label="Pesquisar"
            aria-describedby="button-addon2">
        <button v-on:click="handlePesquisa()" class="btn btn-outline-secondary" type="button" id="button-addon2">
          <font-awesome-icon :icon="['fas', 'search']" fixed-width class="me-1"/>
        </button>
      </div>
    </div>
    <div class="d-flex justify-content-end">
    </div>
    <div class="d-flex">
      <table class="table table-sm-pc table-striped table-hover">
        <thead>
          <tr>
            <th>Identificação</th>
            <th>IP</th>
            <th>Hardware</th>
            <th width="115"></th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="olt in listaOlts" :key="olt.id" v-on:click="abreOlt(olt.id)" class="cursor-pointer" v-bind:class="cssListaOlt(olt)">
              <td scope="row" v-on:click="abreOlt(olt.id)" class="cursor-pointer">
                {{olt.description}}
                <font-awesome-icon v-if="(!olt.synced)" :icon="['fas', 'exclamation-triangle']" class="text-warning" fixed-width/>
              </td>
              <td v-on:click="abreOlt(olt.id)" class="cursor-pointer">{{olt.ip}}</td>
              <td v-on:click="abreOlt(olt.id)" class="cursor-pointer">{{olt.type}}</td>
              <td @click.stop class="text-end">
                <button type="button" v-on:click="handleEditaOlt(olt)" class="btn btn-primary btn-sm me-1">
                  <font-awesome-icon :icon="['fas', 'edit']" fixed-width/>
                </button>
                <button type="button" v-on:click="handleRemoveOlt(olt)" class="btn btn-danger btn-sm">
                  <font-awesome-icon :icon="['fas', 'times']" fixed-width/>
                </button>
              </td>

            </tr>
          </tbody>
      </table>
    </div>
    <Paginador v-model="paginador" @change-paginacao="onChangePaginacao"/>

    <!-- modal-edita-servidor-olt -->
    <!-- Button trigger modal -->
    <button type="button" class="d-none" data-bs-toggle="modal" data-bs-target="#modal-edita-servidor-olt" id="btn-modal-edita-servidor-olt-abre">
      Launch
    </button>

    <!-- Modal -->
    <div class="modal fade" id="modal-edita-servidor-olt" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
      <div class="modal-dialog modal-xl modal-fullscreen-margin" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 v-if="!modalOlt.data.id" class="modal-title">
              <font-awesome-icon :icon="['fas', 'plus']" fixed-width/>
              Cadastrar nova OLT
            </h5>
            <h5 v-if="!!modalOlt.data.id" class="modal-title">
              <font-awesome-icon :icon="['fas', 'pen']" fixed-width/>
              Editar OLT
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div v-if="(modalOlt.data.id)" class="col-12">
                <label class="required">Ativo</label>
                <div class="form-check form-switch">
                  <input :disabled="(!modalOlt.data.synced)" v-model="modalOlt.data.active" class="form-check-input cursor-pointer" type="checkbox" id="flexSwitchCheckDefault">
                  <label class="form-check-label" for="flexSwitchCheckDefault"></label>
                </div>
                <span v-if="(!modalOlt.data.synced)" class="text-danger">
                  <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="text-warning" fixed-width/>
                  Você precisa fazer a carga inicial antes de habilitar a OLT
                </span>

              </div>
              <div class="col-12">
                <label class="required">Tipo da OLT</label>
                <select :disabled="(modalOlt.data.id)" v-model="modalOlt.data.type" class="form-select">
                  <option disabled value="">Selecione</option>
                  <option
                      v-for="oltHw in olts"
                      :key="oltHw.valor"
                      :value="oltHw.valor">
                    {{oltHw.nome}}
                  </option>
                </select>
              </div>
              <div class="col-12 col-sm-6 col-lg-6">
                <label class="required">Identificação</label>
                <input type="text" v-model="modalOlt.data.description" class="form-control">
              </div>
              <div class="col-12 col-sm-6 col-lg-6">
                <label class="required">Endereço IP</label>
                <input type="text" v-model="modalOlt.data.ip" class="form-control">
              </div>
              <div class="col-12 col-sm-4 col-lg-4">
                <label class="required">Porta telnet</label>
                <input type="number" v-model.number="modalOlt.data.telnetPort" min="0" max="65535" class="form-control">
              </div>
              <div class="col-12 col-sm-4 col-lg-4">
                <label class="required">Usuario telnet</label>
                <input type="text" v-model="modalOlt.data.telnetUser" class="form-control">
              </div>
              <div class="col-12 col-sm-4 col-lg-4">
                <label class="required">Senha telnet</label>
                <input type="text" v-model="modalOlt.data.telnetPassword" class="form-control">
              </div>
              <!-- <div class="col-12 col-sm-4 col-lg-4">
                <label class="required">Porta SNMP</label>
                <input type="number" v-model.number="modalOlt.data.porta_snmp" min="0" max="65535" class="form-control">
              </div>
              <div class="col-12 col-sm-4 col-lg-4">
                <label class="required">SNMP Community RO</label>
                <input type="text" v-model="modalOlt.data.snmp_community_ro" class="form-control">
              </div>
              <div class="col-12 col-sm-4 col-lg-4">
                <label class="required">SNMP Community RW</label>
                <input type="text" v-model="modalOlt.data.snmp_community_rw" class="form-control">
              </div> -->
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" id="btn-modal-edita-servidor-olt-fecha">Fechar</button>
            <button type="button"
                v-if="!modalOlt.data.id"
                v-on:click="modalOltCadastrarHandle()"
                v-bind:disabled="!podeSalvarOlt()"
                class="btn btn-success">
              Cadastrar
            </button>
            <button type="button"
                v-if="!!modalOlt.data.id"
                v-on:click="modalOltSalvarHandle()"
                v-bind:disabled="!podeSalvarOlt()"
                class="btn btn-success">
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- modal-edita-servidor-olt -->
  </div>
</template>

<script>
import Paginador from '@/components/Paginador.vue'
import api from '@/modules/api'
import dialogo from '@/modules/dialogo'
import loading from '@/modules/loading'
import clone from '@/modules/clone'
import model from '@/model'

export default {
  name: 'ServidoresOLT',
  components: {
    Paginador,
  },
  data() {
    return {
      /** @type {model.paginador} */
      paginador: clone(model.paginador),
      listaOlts: [],
      listaIPPools: [],
      olts: [
        { valor: "zte_c300", nome: "ZTE c300 series (v2.x)" },
        { valor: "fiberhome_an5516", nome: "Fiberhome AN5516 series" },
        { valor: "nokia_7360", nome: "Nokia 7360 series" },
        { valor: "zyxel_2406", nome: "ZyXEL 2406" },
      ],
      modalOlt: {
        data: {
          id: undefined,
          active: false,
          description: '',
          ip: '',
          telnetPort: 0,
          telnetUser: '',
          telnetPassword: '',
          porta_snmp: 0,
          snmp_community_ro: '',
          snmp_community_rw: '',
          type: '',
          firmwareVersion: '',
        },
      }
    }
  },
  methods: {
    carregaIPPools() {
      api.get(`/isp/pools?paginate.cancel`).then(res => {
        this.listaIPPools = res.data.items;
      }).catch(() => {
        setTimeout(() => {
          this.carregaIPPools();
        }, 1000);
      });
    },
    handlePesquisa() {
      loading(true);

      api.get(`/isp/olts?${this.paginador.requestParams}`).then(res => {
        loading(false);
        this.listaOlts = res.data.items;
        this.paginador.pagina = res.data.page;
        this.paginador.totalItens = res.data.totalItems;
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    onChangePaginacao() {
      this.handlePesquisa();
    },

    cssListaOlt(olt) {
      const css = ['text-info'];

      if (!olt.active) {
        css.push("text-muted-2");
      }

      return css;
    },

    abreOlt(id) {
      this.$router.push({ path: `/configuracoes/servidores/olts/${id}` });
    },

    handleAdicionar() {
      this.resetmodalOlt();
      this.abreModalCadastroOlt();
    },

    async handleEditaOlt(olt) {
      this.resetmodalOlt();

      this.modalOlt.data = clone(olt);

      this.abreModalCadastroOlt();
    },

    handleRemoveOlt(olt) {
      dialogo.confirmacao(`Confirma remover a OLT <b>${olt.description}</b>?`).then(() => {
        loading(true);

        api.delete(`/isp/olts/${olt.id}`).then(() => {
          loading(false);

          this.handlePesquisa();
        }).catch(error => {
          loading(false);
          dialogo.erroHttp(error);
        });
      });
    },

    abreModalCadastroOlt() {
      document.getElementById('btn-modal-edita-servidor-olt-abre').click();
    },

    resetmodalOlt() {
      this.modalOlt.data.id = undefined;
      this.modalOlt.data.active = false;
      this.modalOlt.data.description = '';
      this.modalOlt.data.ip = '';
      this.modalOlt.data.telnetPort = 0;
      this.modalOlt.data.telnetUser = '';
      this.modalOlt.data.telnetPassword = '';
      // this.modalOlt.data.porta_snmp = 0;
      // this.modalOlt.data.snmp_community_ro = '';
      // this.modalOlt.data.snmp_community_rw = '';
      this.modalOlt.data.type = '';
      this.modalOlt.data.firmwareVersion = '';
    },

    podeSalvarOlt() {
      switch (true) {
        case (this.modalOlt.data.description.length < 3):
        case (this.modalOlt.data.ip.length < 7):
        case (this.modalOlt.data.telnetPort < 1):
        case (this.modalOlt.data.telnetUser.length < 1):
        case (this.modalOlt.data.telnetPassword.length < 1):
        case (this.modalOlt.data.type.length < 1):
          return false;

        default:
          return true;
      }
    },

    modalOltCadastrarHandle() {
      const sendData = {...this.modalOlt.data};

      loading(true);

      api.post('/isp/olts', sendData).then(() => {
        loading(false);
        document.getElementById('btn-modal-edita-servidor-olt-fecha').click();
        this.handlePesquisa();
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    modalOltSalvarHandle() {
      const sendData = {
          "id": this.modalOlt.data.id,
          "active": this.modalOlt.data.active,
          "description": this.modalOlt.data.description,
          "ip": this.modalOlt.data.ip,
          "telnetPort": this.modalOlt.data.telnetPort,
          "telnetUser": this.modalOlt.data.telnetUser,
          "telnetPassword": this.modalOlt.data.telnetPassword,
      }

      loading(true);
      api.put(`/isp/olts`, sendData).then(() => {
        loading(false);
        document.getElementById('btn-modal-edita-servidor-olt-fecha').click();
        this.handlePesquisa();
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    listaIPPoolsPorTipo(tipoIP, tipoGrupo) {
      const resultado = this.listaIPPools.
          filter(x => (x.tipo_ip === tipoIP && x.tipo_grupo === tipoGrupo));

      return resultado;
    },

    modalOltChangeVersaoHardware() {
      this.modalOlt.firmwareVersion = '';
    },
  },

  created() {
    this.handlePesquisa();
    this.carregaIPPools();

    // document.getElementById('btn-modal-edita-servidor-olt-fecha').click();
    /*
    setTimeout(() => {
      this.handleAdicionar();

      this.modalOlt.data.description = 'OLT Teste Front';
      this.modalOlt.data.telnetPort = '123465';
      this.modalOlt.data.telnetUser = 0;
      this.modalOlt.data.telnetPassword = 0;
    }, 500);
    /* */
  }
}
</script>
